<template>
  <div v-auto-animate class="expansion-item">
    <div class="expansion-item-title" @click="toggleExpansionItem">
      <h5 class="text-h5-new">{{ props.title }}</h5>
      <span class="expansion-item__arrow">
        <BaseIcon icon="arrow" :class="{ 'expansion-item_open': isOpen }" />
      </span>
    </div>
    <div
      v-if="isOpen && props.text"
      class="expansion-item-text"
      v-html="props.text"
    ></div>
    <slot v-if="isOpen && $slots.default" />
  </div>
</template>

<script setup lang="ts">
import { vAutoAnimate } from "@formkit/auto-animate";
import BaseIcon from "~/components/icons/BaseIcon.vue";

export interface IExpansionItemProps {
  title: string;
  text?: string;
}

const props = defineProps<IExpansionItemProps>();
const isOpen = ref(false);
function toggleExpansionItem() {
  isOpen.value = !isOpen.value;
}
</script>
